.switcher-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 30px 0px 15px;
}

.switcher-container {
  width: 112px; 
  height: 32px;
  border: none;
  border-radius: 2px;
  background-color: white;
  color: black;
  cursor: pointer;
  z-index: 14;
}

.switcher-overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  background-color: transparent;
}

.switcher-button {
  border-radius: 4px;
  background-color: white;
  color: black;
  border: none;
}

.switcher-title-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  width: inherit;
  height: inherit;
}

.switcher-show {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  color: black;
  position: relative;
  top: 1px;
  height: auto;
  max-height: 150px;
  overflow-y: scroll;
}

.switcher-item-separator {
  width: 100%;
  height: 1px;
  background-color: #015DA8;
}

.switcher-show :hover {
  background-color: #015DA8;
}

.switcher-show :hover .switcher-item-text-container{
  color: white;
}

.switcher-item {
  padding: 0px 10px 0px 10px;
  height: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.switcher-item-text-container {
  align-items: center;
  width: 100%;
  height: inherit;
  display: flex;
  font-family: sans-serif;
  font-size: 14px;
  color: #031D35;
}

.switcher-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  margin-right: 10px;
}

.rotate-arrow {
  transform: rotate(0deg);
  transition: transform 0.1s linear;
}

.rotate-arrow.open {
  transform: rotate(180deg);
  transition: transform 0.1s linear;
}

.switcher-icon{
  display: block;
}
