.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-left: 1px solid white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
} 

.content-to-hover {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-hover {
  background-color: #055092;
  cursor: pointer;
}

.category-text {
  font-size: 36px;
  font-weight: bold;
  margin-top: 30px;
  text-align: center;
  color: white;
  font-family: sans-serif;
}

/* CATEGORY CHILDREN */
.category-container {
  width: 100%;
  height: 100%;
  background-color: #055092;
  justify-content: center;
  align-items: center;
  display: flex;
}
.category-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.category-item {
  z-index: 10;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 280px;
  height: 104px;
  background-color: #044278;
  color: white;
  justify-content: center;
  align-items: center;
}

.category-item-h :hover {
  background-color: #002c52;
}


@media (max-height: 700px) {
  .category-column{
    padding-top: 35vh;
  }
}

@media (max-width: 1280px) {
  .category-text {
    font-size: 26px;
    margin-top: 29px;
  }
}

@media (max-width: 1112px) {
  .category-column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
}