.main-header-navigator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  flex: 1;
}

.header-navigator-icon-text-pairs {
  display: flex;
  flex-direction: row;
  height: 80px;
  align-items: center;
}

.header-navigator-icon {
  display: block;
  margin: 0px 5px 0px 5px; 
}

.header-navigator-profile-icon {
  display: block;
  margin-left: 10px; 
  position: relative;
  z-index: 11;
}

.header-navigator-text {
  display: none;
  margin-left: none; 
}

.nav-header-wrapper-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  background-color: transparent;
}

.header-navigator-notification-container {
  position: absolute;
  top: 18px;
  left: 28px;
}

.selected-header-navigation-element {
  background-color: transparent;
}

@media (max-width:1425px) {
  .header-navigator-text {
    display: none;
    margin-left: none; 
  }
}

@media (max-width:1280px) {
  .selected-header-navigation-element {
    background-color: #257EBD;
    height: 60px;
  }
}
