/* Common for all charts made with ChartJS */
.chart-container {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
}

/* Style for the second (header) row */
.second_header_row {
  display: flex;
  flex-direction: row;
  background-color: #031D35;
  height: 64px;
  margin-top: -64px;
  z-index: 9;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1280px) {
  .second_header_row {
    height: 49px;
    margin-top: -49px;
  }
  
}