.header-container {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: "row";
  justify-content: "space-between";
  align-items: "center";
}

.header-content {
  display: flex;
  flex-direction: row;
  height: 80px;
  width: 100%;
  background-color: white;
  align-items: center;
}

.header-title-container {
  margin-left: 44.5px;
  margin-right: 10px;
}

.header-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: inherit; 
}

.header-right-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 2;
  justify-content: space-between;
  height: inherit;
}

.header-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
  justify-content: space-between;
  height: inherit;
}

.header-left-view {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.header-logo {
  cursor: pointer;
}

@media (max-width:1112px) {
  .header-title-container {
    margin-left: 20.5px;
  }
  
}

@media (max-width: 1280px) {
  .header-container {
    height: 60px;
  }
}
