.contextual-main-container {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 1;
}

.contextual-overlay {
  display: flex;
  padding-right: 12px;
  background-color: rgb(3, 29, 53);
  opacity: 0.73;
  flex: 1;
}

.contextual-content-container {
  height: calc(100% - 80px);
  width: 450px;
  display: flex;
  background-color: white;
  flex-direction: column;
}

.contextual-header {
  height: 64px;
  background-color: #015DA8;
  border-top: 1px solid rgb(69, 136, 189); 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px 0px 32px;
}

.contextual-content {
  padding: 32px 32px 60px 32px;
  height: 100%;
  overflow-y: scroll;
}


.contextual-close-icon  {
  position: relative;
  height: 20px; 
  width: 20px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.contextual-close-icon::before, .contextual-close-icon::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px; /* cross thickness */
  background-color: #9BEAFC;
}

.contextual-close-icon::before {
  transform: rotate(45deg);
}

.contextual-close-icon::after {
  transform: rotate(-45deg);
}

@media (max-width:1280px) {
  .contextual-main-container {
    top: 60px;
  }
}