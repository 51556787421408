.entityChart-content-container {
  flex: 1 1 0%;
  height: calc(100% - 86px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.entityChart-scrollable-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.drillup-back-arrow {
  position: fixed;
  z-index: 2;
  background-color: rgb(232, 232, 232);
  width: 30px;
}

.barChart-yAxis_labels {
  width: 60px;
  background-color: rgb(255, 255, 255);
  overflow: clip;
  z-index: 2;
  position: fixed;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 0.5px solid #cadbeb;
  height: calc((100% - 90px - 51px - 35px - 144px) / 3); /* all margins padding and other view heights */
  display: none;
  border-right: 0.5px solid #CADBEB;
}

.barChart-yAxis-ticks {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;
  margin-right: 10px;
  margin-left: 13px;
}
.barChart-yAxis_label {
  font-weight: bold;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
}
