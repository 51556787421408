.logout-main-container {
  width: 240px;
  background-color: white;
  display: flex;
  position: absolute;
  top: 45px;
  right: -15px;
  flex-direction: column;
  padding: 10px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 14;
}

.logout-main-container:before {
  content:"";
  position: absolute;
  right: 23px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
  z-index:9999;
}

.logout-user-info-container {
  width: 100%;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-evenly;
}

.logout-user-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.logout-horosontal-separator {
  width: 100%;
  height: 1px;
  background-color: #015DA8;
  margin: 10px 0px;
}

.logout-logout-container {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  margin: 10px 0px;
}

@media (max-width: 1280px) {
  .logout-main-container {
    top: 38px;
  }
}