
.menu-title-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    margin-left: 32px;
    align-content: center;
    align-items: center;
}

.menu-column {
    display: block;
    position: absolute;
    top: 64px;
    z-index: 9;
    background-color: white;
    width: 280px;
    height: calc(100% - 64px);
    border-left: 1px solid #2477C3
}

.blockDisplay{
    display: block !important;
}

.absolute-overlay {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 80px);
    z-index: 10;
    background-color: rgb(3, 29, 53);
    display: none;
    left: 0;
    top: 0;
    opacity: 0.73;
    overflow: hidden;
}

.menu-column:first-child{
    border-left: none
  }

.main-menu-container {
    display: flex;
    height: 64px;
    width: 25vw;
    background-color: #031D35;
    justify-content: center;
    flex-direction: column;
    color: white;
    z-index: 10;
}

.dropdown-menu {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

.dropdown-menu::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}


.menu-columns {
    display: flex;
    flex-direction: row;
}


/* STYLES FOR MENU ITEM COMPONENT */
.preserve-hover-state {
    background-color: #015DA8;
}
.preserve-hover-state .menu-item-title{
    color: white;
}
.preserve-hover-state .menu-item-description {
    color: white;
}
.preserve-hover-state  .arrow {
    height: 2px;
    width: 2px;
    border-right: solid white;
    border-bottom: solid white;
    border-width: 0 1px 1px 0 !important;
}

.next-icon{
    display: flex;
    height: inherit;
    justify-content: center;
    padding-right: 24px;
    align-items: center;
}

.menu-item :hover {
    cursor: pointer;
    background-color: #015DA8;
    color: white;
}
.menu-item :hover .menu-item-title {
    color: white;
}
.menu-item :hover .menu-item-description {
    color: white;
}
.menu-item :hover .arrow {
    height: 2px;
    width: 2px;
    border-right: solid white;
    border-bottom: solid white;
    border-width: 0 1px 1px 0 !important;
}

.menu-item-main-container {
    height: 73px;
    display: flex;
    flex-direction: column;
}

.menu-item-container {
    flex-direction: row;
    display: flex;
    height: calc(100% - 1px);
    padding-left: 32px;
}

.menu-item-text-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    height: inherit;
    width: 200px;
}

.menu-item-separator {
    height: 1px;
    background-color: #2477C3;
    margin-left: 33px;
    margin-right: 24px;
}

.menu-item-title {
    font-family: sans-serif;
    font-size: 16px;
    color: #031D35;
    font-weight: bold;
}

.menu-item-description {
    font-family: sans-serif;
    color: #031D35;
    margin-top: 8px;
    font-weight: lighter;
    font-size: 14px;
    block-size: revert;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 18px;
    max-height: 24px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.arrow {
    height: 2px;
    width: 2px;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

@media (max-width:1280px) {
    .main-menu-container {
        height: 49px;
    }
    .menu-column {
        top: 49px;
        height: calc(100% - 49px);
    }
    .absolute-overlay {
        height: calc(100vh - 60px);
    }

    .menu-item-text-container {
         width: 180px; 
    }

    .menu-item-main-container {
        height: 49px;
    }

    .menu-item-container {
        padding-left: 24px;
    }

    .menu-item-separator {
        margin-left: 24px;
        margin-right: 24px;
    }

    .menu-column {
        width: 248px;
    }
    .main-menu-container {
        width: 25vw;
    }

    .menu-item-title {
        font-size: 14px;
    }
    
    .menu-item-description {
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
    }
}