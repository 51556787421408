.chart-container-tab-icon {
  display: block;
}

.category-tab-container {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0px 20px 0px 0px;
}
.category-tab-width {
  width: 50%;
  max-width: 250px;
}
.category-tab-content {
  height: 80px;
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 30px;
}
.subcategory-item-container {
  display: none;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.subcategory-item-container-show {
  display: block !important;
}

.chart-tab-container {
  height: 61px;
  margin-left: 12px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}

@media (max-width: 1280px) {
  .category-tab-container {
    height: 60px;
  }
  .category-tab-content {
    height: 60px;
  }
  .chart-tab-container {
    height: 46px;
  }
}

@media (max-width:1112px) {
  .chart-container-tab-icon {
    display: none;
  }
}