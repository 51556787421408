.image {
  display: flex;
  image-rendering: optimizeQuality; 
  filter: invert(100%);
  justify-content: center;
  width: 20px;
  height: auto;
}
.invert-image {
  display: flex;
  filter: invert(0%);
  justify-content: center;
  image-rendering: optimizeQuality;
  width: 20px;
  height: auto;
}